import React, { useEffect, useState } from 'react'
import Header from '../../Header/Header'
import { background06 } from '../../images'
import SelectHead from './partials/SelectHead'
import { AppLogger } from '../../../AppLogger'
import Rooms from './partials/Rooms/Rooms'
import { BdloApiAxios } from '../../../BdloApiAxios'
import useToggle from '../../hooks/useToggle'
import ScreenLoader from '../../ScreenLoader'
import { loadPartialConfig } from '@babel/core'
import ErrorPage from '../../ErrorPage/ErrorPage'

const logger = AppLogger.getInstance()
export enum RoomType {
    AULA = 'AULA',
    OTHERS = 'OTHERS',
}

export interface IRoom {
    id: number
    code: string
    designation: string
    nombrePlace: number | null
    reservable: boolean
    typeLocal: {
        id: number
        designation: string
    }
    typeLocalOFFT: {
        id: number
        designation: string
    }
    batiment: {
        id: number
        designation: string
        adresseLigne1: string
        npa: string
        localite: string
    }
}
export interface IBuildingInfo {
    name: string
    description: string
    description2: string
}
interface IPage {
    building: IBuildingInfo | null
    rooms: IRoom[]
    loading: boolean
}
interface RoomSelectionProps {
    buildingId: number | undefined
}
let hideBack: boolean
const RoomSelection = (props: RoomSelectionProps) => {
    const { buildingId } = props
    const [roomType, setRoomType] = useState<RoomType>(RoomType.AULA)
    const [page, setPage] = useState<IPage>({
        building: null,
        rooms: [],
        loading: false,
    })

    const handleTypeClick = (type: RoomType): void => {
        setRoomType(type)
    }
    useEffect(() => {
        const axiosApiLayer = BdloApiAxios.getInstance()
        const getData = async () => {
            try {
                setPage({ ...page, loading: true })
                const res = await axiosApiLayer.get(
                    `batiments/${buildingId}/locaux`
                )

                const { localResourceList } = res.data._embedded
                const rooms = localResourceList.filter(
                    (r: IRoom) => r.reservable === true
                )
                const building: IBuildingInfo = {
                    name: localResourceList[0].batiment.abreviation,
                    description: `${localResourceList[0].batiment.adresseLigne1}`,
                    description2: `${localResourceList[0].batiment.npa} -${localResourceList[0].batiment.localite}`,
                }
                setPage({ building, rooms, loading: false })
            } catch (e) {
                logger.error(e.message)
                setPage({ ...page, loading: false })
            }
        }
        getData()
    }, [buildingId])

    return (
        <div className="wrapper">
            <div className="main-background">
                <img src={background06} alt="background 01" />
            </div>
            <Header info={page.building} />
            <main className="main-content select">
                <SelectHead
                    hideBack={hideBack}
                    onTypeClick={handleTypeClick}
                    roomType={roomType}
                    rooms={page.rooms}
                    buildingId={buildingId}
                />
                {page.loading ? (
                    <ScreenLoader />
                ) : page.rooms.length > 0 ? (
                    <Rooms rooms={page.rooms} roomType={roomType} />
                ) : (
                    <div className="select-wrapper">
                        <div className="select-container">
                            <div className="center">
                                <h1
                                    className="intro-text"
                                    style={{
                                        color: 'red',
                                        marginTop: '-120px',
                                    }}
                                >
                                    Il n'y a pas de locaux réservables dans ce
                                    bâtiment.
                                    <br />
                                    Veuillez sélectionner un autre bâtiment.
                                </h1>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </div>
    )
}

export default RoomSelection
