import React from 'react'

const MyEvent = (props: any) => {
    const {
        event: { resource },
    } = props

    const { typeoccupation, matiere, prof, remarque } = resource

    const formatProf = (profs: string[] | string): string => {
        let profsStr = ''
        if (Array.isArray(profs)) {
            profs.forEach((p: string, index: number) => {
                let [first, ...second] = p.split(' ')
                const secondP = second.join(' ')
                if (index === profs.length - 1) {
                    // profsStr += ` ${first.charAt(0)} ${secondP}`
                    profsStr += ` ${secondP.charAt(0)} ${first}`
                } else {
                    // profsStr += ` ${first.charAt(0)} ${secondP}, `
                    profsStr += ` ${secondP.charAt(0)} ${first}, `
                }
            })
        } else {
            let [first, ...second] = profs.split(' ')
            const secondP = second.join(' ')
            //profsStr = ` ${first.charAt(0)} ${secondP}`
            profsStr = ` ${secondP.charAt(0)} ${first}`
        }

        return profsStr
    }
    if (typeoccupation === 'pires') {
        return (
            <div>
                <p style={{ color: 'rgba(255, 255, 255, 0.8)' }}>{remarque}</p>
                <p
                    style={{
                        color: 'rgba(255, 255, 255)',
                        paddingTop: '5px',
                    }}
                >
                    {formatProf(prof)}
                </p>
            </div>
        )
    } else {
        return (
            <div>
                <p style={{ color: 'rgba(255, 255, 255, 0.8)' }}>{matiere}</p>
                <p style={{ color: 'rgba(255, 255, 255)', paddingTop: '5px' }}>
                    {formatProf(prof)}
                </p>
            </div>
        )
    }
}
export default MyEvent
