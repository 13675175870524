import React from 'react'
import { Router, Switch } from 'react-router-dom'

import history from './history'
import PublicRoute from './PublicRoute'
import { Home } from '../components/Home/Home'

const AppRouter = () => (
    <Router history={history}>
        <Switch>
            <PublicRoute path="/" exact component={Home} />
        </Switch>
    </Router>
)

export default AppRouter
