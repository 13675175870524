import React from 'react'
import moment from 'moment'
import * as QueryString from 'query-string'
import { withRouter } from 'react-router-dom'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import { Navigate } from 'react-big-calendar'

let forecast = 0
class MyCustomTimeGrid extends React.Component {
    componentDidMount() {
        const params = QueryString.parse(window.location.search)
        if (params && params.forecast) {
            forecast = params.forecast
        }
    }

    render() {
        let range = MyCustomTimeGrid.range(forecast)

        return <TimeGrid {...this.props} range={range} />
    }
}

MyCustomTimeGrid.range = forecast => {
    let rangeDates = []
    do {
        const current = moment().startOf('day')
        rangeDates.push(current.add(forecast, 'day').toDate())
        forecast--
    } while (forecast >= 0)

    return rangeDates.reverse()
}

MyCustomTimeGrid.navigate = (date, action) => {
    let nextForecast = forecast
    let prevForecast = -forecast
    if (forecast === 0) {
        nextForecast = 1
        prevForecast = -1
    }
    switch (action) {
        case Navigate.PREVIOUS:
            return moment().add(date, prevForecast, 'day')

        case Navigate.NEXT:
            return moment().add(date, nextForecast, 'day')

        default:
            return date
    }
}

MyCustomTimeGrid.title = date => {
    if (forecast === 0) {
        return `[${moment().format('dddd, DD MMMM YYYY')}]`
    }
    return `[ ${moment().format('DD')} ${moment().format('MMMM')} - ${moment()
        .add(forecast, 'days')
        .format('DD')} ${moment()
        .add(forecast, 'days')
        .format('MMMM')}]`
}

MyCustomTimeGrid = withRouter(MyCustomTimeGrid)

export default MyCustomTimeGrid
