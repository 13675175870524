import React from 'react'
import Room from './Room/Room'
import { IRoom, RoomType } from '../../RoomSelection'

const Rooms = (props: any) => {
    const { rooms, roomType } = props

    // fliter upon the selected type
    let filteredRooms: IRoom[] = []
    switch (roomType) {
        case RoomType.AULA:
            filteredRooms = rooms.filter((r: IRoom) => r.typeLocalOFFT.id === 2)
            break
        default:
            filteredRooms = rooms.filter((r: IRoom) => r.typeLocalOFFT.id !== 2)
            break
    }
    return (
        <div className="select-wrapper">
            <div className="select-container">
                {filteredRooms &&
                    filteredRooms.map((d: IRoom) => (
                        <Room key={d.id} room={d} />
                    ))}
            </div>
        </div>
    )
}

export default Rooms
