import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { RoomType, IRoom } from '../RoomSelection'
import { back } from '../../../images'

const SelectHead = (props: any) => {
    const { roomType, onTypeClick, rooms, buildingId } = props
    const [aulas, setAulas] = useState<IRoom[]>([])
    const [others, setOthers] = useState<IRoom[]>([])

    useEffect(() => {
        const aulas = rooms.filter((r: IRoom) => r.typeLocalOFFT.id === 2)
        const others = rooms.filter((r: IRoom) => r.typeLocalOFFT.id !== 2)
        setAulas(aulas)
        setOthers(others)
        if (aulas.length === 0 && others.length > 0) {
            onTypeClick(RoomType.OTHERS)
        }
    }, [buildingId, rooms, onTypeClick])

    const renderTabs = (): JSX.Element => {
        if (aulas.length > 0 && others.length > 0) {
            return (
                <div className="tabs">
                    <div
                        onClick={() => onTypeClick(RoomType.AULA)}
                        className={`tab ${
                            roomType === RoomType.AULA ? 'active' : ''
                        }`}
                    >
                        Auditoires
                    </div>
                    <div
                        onClick={() => onTypeClick(RoomType.OTHERS)}
                        className={`tab ${
                            roomType === RoomType.OTHERS ? 'active' : ''
                        }`}
                    >
                        Autres
                    </div>
                </div>
            )
        } else {
            return <span></span>
        }
    }

    return (
        <div className="select-head">
            <div
                className={`left ${props.hideBack ? 'hide' : ''}`}
                onClick={() => props.history.push('/')}
            >
                <button className="btn btn-back">
                    <img src={back} alt="back" />
                </button>
            </div>

            <div className="center">{renderTabs()}</div>
        </div>
    )
}

export default withRouter(SelectHead)
