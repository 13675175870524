import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import * as blobUtil from 'blob-util'
import { IRoom } from '../../../RoomSelection'
import { BdloApiAxios } from '../../../../../../BdloApiAxios'
import { imageNA } from '../../../../../images'

const Room = (props: any) => {
    const room: IRoom = props.room
    const [roomImg, setRoomImg] = useState()
    const handleOpenRoom = (): void => {
        props.history.push(`/?salleId=${room.id}&forecast=6`)
    }

    useEffect(() => {
        const axiosImageApiLayer = BdloApiAxios.getInstance()
        const getData = async () => {
            try {
                const response = await axiosImageApiLayer.get(
                    `/locaux/${room.id}/photo/mini`,
                    { responseType: 'arraybuffer' }
                )
                const buffer = Buffer.from(response.data, 'base64')
                if (buffer && buffer.length > 0) {
                    const blob = blobUtil.arrayBufferToBlob(buffer)
                    const dataURL = await blobUtil.blobToDataURL(blob)
                    setRoomImg(dataURL)
                } else {
                    setRoomImg(imageNA)
                }
            } catch (e) {}
        }
        getData()
    }, [room.id])

    return (
        <div
            className={`select-card color-chathams-blue`}
            onClick={handleOpenRoom}
        >
            <div className="card-inner">
                <div className="card-background"></div>
                <div className="card-content">
                    <h2 className="card-title">{`${room.code} - ${room.typeLocal.designation}`}</h2>
                    {room.designation &&
                        room.designation
                            .toLocaleLowerCase()
                            .indexOf('ubicast') > -1 && (
                            <h2 className="card-title">
                                UbiCast (Enregistrement cours)
                            </h2>
                        )}

                    <p className="date-place">
                        <span className="date">
                            Nbr. Places: {room.nombrePlace}
                        </span>
                        <span className="place"></span>
                    </p>
                    <div className="seprator"></div>
                    <p className="card-description small"></p>
                </div>
                <div className="card-icons">
                    <div className="info-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.802"
                            height="36.592"
                            viewBox="0 0 20.802 36.592"
                        >
                            <g id="arrow-right" transform="translate(-22.349)">
                                <g id="sign-in" transform="translate(22.349 0)">
                                    <g
                                        id="Group_40596"
                                        data-name="Group 40596"
                                        transform="translate(0 0)"
                                    >
                                        <path
                                            id="Path_114263"
                                            data-name="Path 114263"
                                            d="M39.783,37.775,25.323,51.648a2.619,2.619,0,0,0,0,3.616,2.531,2.531,0,0,0,1.751.784,2.434,2.434,0,0,0,1.749-.7L44.676,39.517a2.46,2.46,0,0,0,0-3.487l-.752-.751a2.447,2.447,0,0,0-.635-.632L28.823,20.181a2.474,2.474,0,0,0-3.5,0,2.2,2.2,0,0,0,0,3.264l10.53,10.293Z"
                                            transform="translate(-24.599 -19.456)"
                                            fill="#fff"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>

                    <div className="main-icon">
                        <img
                            src={roomImg}
                            style={{
                                height: '120px',
                                width: '120px',
                                borderRadius: '50%',
                            }}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Room)
