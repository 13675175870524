import React, { useState, useEffect } from 'react'
import schedule from 'node-schedule'
import Fullscreen from 'react-full-screen'
import { Calendar, Event, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { OccupationApiAxios } from '../../OccupationApiAxios'
import { AppLogger } from '../../AppLogger'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MyEvent from './MyEvent'
import MyCustomTimeGrid from './MyCustomTimeGrid'
import { parseEvents } from './utils'

interface IRoomInfo {
    name: string
    description: string
    description2: string
    forecast: number
    salleplaces?: number
}

const logger = AppLogger.getInstance()
// Setup the localizer by providing the moment (or globalize) Object
moment.locale('fr')

// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
interface RoomCalendarProps {
    salleId: number | undefined
    forecast: number | undefined
}
const RoomCalendar = (props: RoomCalendarProps) => {
    const { salleId, forecast } = props

    const [myEventsList, setMyEventsList] = useState<Event[]>([])
    const [isOccupied, setOccupied] = useState<boolean>(false)
    const [info, setInfo] = useState<IRoomInfo>({
        name: '',
        description: '',
        description2: '',
        forecast: 0,
    })

    useEffect(() => {
        console.log(
            `setup refreshJob  at ${new Date().toLocaleString()} to full page refresh on hh:00 && hh:30`
        )
        const refreshJob = schedule.scheduleJob(
            { minute: [30, 60] },
            function () {
                console.log(`refreshJob ran at ${new Date().toLocaleString()}`)
                window.location.reload(true)
            }
        )

        return () => {
            console.log(
                `refreshJob cancelled at ${new Date().toLocaleString()}`
            )
            schedule.cancelJob(refreshJob)
        }
    }, [])

    useEffect(() => {
        // salleId = 905 549 446
        const axiosApiLayer = OccupationApiAxios.getInstance()
        let pollingInterval: any
        const pollingFreqEnvVariable = process.env.REACT_APP_POLLING_FREQUENCY
        let pollingFreq = 2000
        if (pollingFreqEnvVariable) {
            pollingFreq = parseInt(pollingFreqEnvVariable)
        }
        const polling = async () => {
            try {
                // call the service
                const myForecast = forecast || 0
                const debut = moment().format('YYYY-MM-DD')
                const fin = moment()
                    .add(myForecast, 'days')
                    .format('YYYY-MM-DD')
                const res = await axiosApiLayer.get(
                    `?salleid=${salleId}&debut=${debut}&fin=${fin}`
                )
                if (res.data) {
                    const {
                        batiment,
                        salle,
                        salleplaces,
                    } = res.data.Query.Horaire
                    setInfo({
                        description: batiment,
                        description2: '',
                        name: salle,
                        salleplaces,
                        forecast: myForecast,
                    })

                    const jours = res.data.Query.Horaire.jour
                    let events: Event[] = []
                    if (jours) {
                        if (Array.isArray(jours)) {
                            logger.log('jours is an array')
                            jours.forEach((j: any) => {
                                const _events = parseEvents(j)
                                events = [...events, ..._events]
                            })
                        } else {
                            logger.log('jours is a single object')
                            const _events = parseEvents(jours)
                            events = [..._events]
                        }
                        setMyEventsList(events)
                    } else {
                        logger.warn(' there is no jour object')
                        setMyEventsList([])
                    }
                } else {
                    logger.warn(' there is no data')
                    setMyEventsList([])
                }
            } catch (e) {
                logger.error(e.message)
            }
        }

        if (salleId) {
            polling()
            pollingInterval = setInterval(polling, pollingFreq)
        }

        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval)
            }
        }
    }, [salleId, forecast])

    /**
     * Color the reservation bloc
     * Opacity is 0.5 when  past  reservation
     * Orange when active reservation
     * Blue when futur reservation
     */
    const eventStyleGetter = (
        event: any,
        start: any,
        end: any,
        isSelected: boolean
    ) => {
        let backgroundColor = ''
        let boxShadow = ''
        let opacity = 1
        const now = moment()
        if (moment(now).isBetween(moment(start), moment(end))) {
            // current event
            setOccupied(true)
            backgroundColor = '#FFA500'
            boxShadow = 'inset 0 0 5px 5px #cc5500'
        } else if (moment(end).isBefore(now)) {
            // past events
            backgroundColor = '#3174ad'
            opacity = 0.5
            boxShadow = ''
            setOccupied(false)
        } else if (moment(start).isAfter(now)) {
            // futur events
            backgroundColor = '#3174ad'
            opacity = 0.9
        }
        const style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity,
            color: 'white',
            border: '1px dashed white',
            display: 'block',
            boxShadow,
        }
        return {
            style: style,
        }
    }

    console.log(
        `myEventsList to display: ${JSON.stringify(myEventsList, undefined, 5)}`
    )
    let formats = {
        dayFormat: 'ddd DD',
        timeGutterFormat: 'HH',
    }
    return (
        <Fullscreen enabled={false}>
            <div className="wrapper">
                <Header info={info} />
                <main className="main-content select">
                    <div>
                        <Calendar
                            localizer={localizer}
                            formats={formats}
                            events={myEventsList}
                            defaultView="week"
                            views={{ week: MyCustomTimeGrid }}
                            min={moment().startOf('day').add(8, 'h').toDate()}
                            max={moment()
                                .endOf('day')
                                .subtract(5, 'h')
                                .toDate()}
                            components={{
                                event: MyEvent,
                            }}
                            eventPropGetter={eventStyleGetter}
                        />
                    </div>
                </main>
                <Footer isOccupied={isOccupied} />
            </div>
        </Fullscreen>
    )
}

export default RoomCalendar
