import React from 'react'
import { background06 } from '../../images'
import Header from '../../Header/Header'
import SelectHead from './partials/SelectHead'
import Buildings from './partials/Buildings/Buildings'

const BuildingSelection = (props: any) => {
    const { data } = props
    return (
        <div className="wrapper">
            <div className="main-background">
                <img src={background06} alt="background 01" />
            </div>
            <Header />
            <main className="main-content select">
                <SelectHead />
                <Buildings data={data} />
            </main>
        </div>
    )
}

export default BuildingSelection
