import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { spacedBackground1 } from '../../../../../images'
import * as blobUtil from 'blob-util'
import { BdloApiAxios } from '../../../../../../BdloApiAxios'
import { IBuilding } from '../../../../../cacheManager'

const Building = (props: any) => {
    const building: IBuilding = props.building
    const [buildingImg, setBuildingImg] = useState()

    const handleBuildingSelection = (): void => {
        props.history.push(`/?buildingId=${building.id}`)
    }

    useEffect(() => {
        let unmounted = false
        const axiosImageApiLayer = BdloApiAxios.getInstance()
        const getData = async () => {
            try {
                const response = await axiosImageApiLayer.get(
                    `/batiments/${building.id}/photo/mini`,
                    { responseType: 'arraybuffer' }
                )
                const buffer = Buffer.from(response.data, 'base64')
                const blob = blobUtil.arrayBufferToBlob(buffer)
                const dataURL = await blobUtil.blobToDataURL(blob)
                if (!unmounted) {
                    setBuildingImg(dataURL)
                }
            } catch (e) {}
        }
        getData()

        return () => {
            unmounted = true
        }
    }, [building.id])

    return (
        <div
            className={`select-card card-branch color-white`}
            onClick={handleBuildingSelection}
        >
            <div className="card-inner">
                <div className="card-background">
                    <img src={spacedBackground1} alt="background-1" />
                </div>
                <div className="card-content">
                    <h2 className="card-title">
                        <span className="branch-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31.473"
                                height="42.5"
                                viewBox="0 0 31.473 42.5"
                            >
                                <g id="marker" transform="translate(1.25 1.25)">
                                    <g id="Group_40593" data-name="Group 40593">
                                        <path
                                            id="Path_114261"
                                            data-name="Path 114261"
                                            d="M85.059,0A14.5,14.5,0,0,0,70.573,14.486c0,9.913,12.964,24.466,13.516,25.081a1.3,1.3,0,0,0,1.941,0c.552-.615,13.516-15.168,13.516-25.081A14.5,14.5,0,0,0,85.059,0Zm0,21.775a7.288,7.288,0,1,1,7.288-7.288A7.3,7.3,0,0,1,85.059,21.775Z"
                                            transform="translate(-70.573 0)"
                                            fill="none"
                                            stroke="#164171"
                                            strokeWidth="2.5"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        {building.abreviation}
                    </h2>
                    <div className="seprator"></div>
                    <p className="card-description">
                        {building.adresseLigne1}
                        <br></br>
                        {building.npa}, {building.localite}
                    </p>
                </div>
                <div className="card-icons">
                    <div className={`info-icon `}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.802"
                            height="36.592"
                            viewBox="0 0 20.802 36.592"
                        >
                            <g id="arrow-right" transform="translate(-22.349)">
                                <g id="sign-in" transform="translate(22.349 0)">
                                    <g
                                        id="Group_40596"
                                        data-name="Group 40596"
                                        transform="translate(0 0)"
                                    >
                                        <path
                                            id="Path_114263"
                                            data-name="Path 114263"
                                            d="M39.783,37.775,25.323,51.648a2.619,2.619,0,0,0,0,3.616,2.531,2.531,0,0,0,1.751.784,2.434,2.434,0,0,0,1.749-.7L44.676,39.517a2.46,2.46,0,0,0,0-3.487l-.752-.751a2.447,2.447,0,0,0-.635-.632L28.823,20.181a2.474,2.474,0,0,0-3.5,0,2.2,2.2,0,0,0,0,3.264l10.53,10.293Z"
                                            transform="translate(-24.599 -19.456)"
                                            fill="#fff"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="main-icon">
                        <img
                            src={buildingImg}
                            style={{
                                height: '120px',
                                width: '120px',
                                borderRadius: '50%',
                            }}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Building)
