import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'

const ScreenLoader = () => {
    return (
        <div className="loading show">
            <div className="loading-inner">
                <div className="loading-icon">
                    <ScaleLoader
                        height={35}
                        width={6}
                        radius={3}
                        color={'#153f6e'}
                        margin="2px"
                    />
                </div>
            </div>
        </div>
    )
}

export default ScreenLoader
