import React, { useState, useEffect, Fragment } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { logoUniNE, frameing } from '../images'
import { AppLogger } from '../../AppLogger'

const logger = AppLogger.getInstance()
const Header = (props: any) => {
    const { info } = props

    logger.log(info)

    const [currentTime, setCurrentTime] = useState<string>(
        moment().format('HH:mm')
    )

    useEffect(() => {
        logger.debug('setup  momentInterval in stateless header')
        let momentInterval = setInterval(() => {
            setCurrentTime(moment().format('HH:mm'))
        }, 1000)
        return () => {
            logger.debug('cleared  momentInterval in stateless header')
            clearInterval(momentInterval)
        }
    }, [])

    const { i18n } = useTranslation('Header')
    const curreLanguage = i18n.language

    moment.locale(curreLanguage)

    const formatRoomName = (name: string): string => {
        if (name.length > 10) {
            if (name.indexOf(' - ')) {
                return name.split(' - ')[0]
            } else name.slice(10)
        }
        return name
    }
    return (
        <header className="header">
            <div
                className={`logo ${info && info.forecast === 0 ? 'hide' : ''}`}
            >
                <h1>
                    <img src={logoUniNE} alt="logoUniNE" />
                </h1>
            </div>

            <div className="center-div">
                {info && (
                    <div className="center-container">
                        {info.name.toLowerCase().indexOf('ubicast') > -1 && (
                            <img src={frameing} alt="" />
                        )}
                        &nbsp;
                        <div className="center-text">
                            <h2>
                                {formatRoomName(info.name)}{' '}
                                {info.salleplaces &&
                                    `( ${info.salleplaces}
                            Pl.)`}
                            </h2>
                            <h4>{info.description}</h4>
                            <h4>{info.description2}</h4>
                        </div>
                    </div>
                )}
            </div>

            <div
                className={`time-date ${
                    info && info.forecast === 0 ? 'hide' : ''
                }`}
            >
                <p className="time">{currentTime}</p>
                <p className="date opensens">
                    {moment().format('MMM DD, YYYY')}
                </p>
            </div>
        </header>
    )
}

export default Header
