import React from 'react'
import Building from './Building/Building'
import { IBuilding } from '../../../../cacheManager'

const Buildings = (props: any) => {
    const { data } = props
    return (
        <div className="select-wrapper">
            <div className="select-container">
                {data.map((s: IBuilding) => (
                    <Building key={s.id} building={s} />
                ))}
            </div>
        </div>
    )
}

export default Buildings
