const buildingsOrder = [
    {
        code: '14',
        abreviation: 'Alma mater',
        displayOrder: 1,
    },
    {
        code: '18',
        abreviation: 'Faculté des sciences économiques (FSE)',
        displayOrder: 2,
    },
    {
        code: '19',
        abreviation: 'Faculté de droit (FD)',
        displayOrder: 3,
    },
    {
        code: '26',
        abreviation: 'Faculté des lettres et sciences humaines (FLSH)',
        displayOrder: 4,
    },
    {
        code: '30A',
        abreviation: 'Faculté des sciences (FS) unimail A',
        displayOrder: 5,
    },
    {
        code: '30B',
        abreviation: 'Faculté des sciences (FS) unimail B',
        displayOrder: 6,
    },
    {
        code: '30C',
        abreviation: 'Faculté des sciences (FS) unimail C',
        displayOrder: 7,
    },
    {
        code: '30D',
        abreviation: 'Faculté des sciences (FS) unimail D',
        displayOrder: 8,
    },
    {
        code: '30E',
        abreviation: 'Faculté des sciences (FS) unimail E',
        displayOrder: 9,
    },
    {
        code: '30F',
        abreviation: 'Faculté des sciences (FS) unimail F',
        displayOrder: 10,
    },
    {
        code: '30T',
        abreviation: 'Faculté des sciences (FS) unimail T',
        displayOrder: 11,
    },
    {
        code: '29',
        abreviation: 'Faculté des sciences (FS) unimail G',
        displayOrder: 12,
    },
    {
        code: '10',
        abreviation: 'Rectorat',
        displayOrder: 13,
    },
    {
        code: '01',
        abreviation: "Institut d'ethnologie (FLSH)",
        displayOrder: 14,
    },

    {
        code: '08',
        abreviation: 'Faculté des lettres et sciences humaines (FLSH)',
        displayOrder: 15,
    },
    {
        code: '09',
        abreviation: 'Institut de sociologie (FLSH)',
        displayOrder: 16,
    },

    {
        code: '15',
        abreviation: 'Domaine central',
        displayOrder: 17,
    },
    {
        code: '16',
        abreviation:
            'Institut de langue et civilisation françaises (ILCF - FLSH)',
        displayOrder: 18,
    },
    {
        code: '17',
        abreviation: 'Université du 3ème âge',
        displayOrder: 19,
    },
    {
        code: '31',
        abreviation: 'Latenium',
        displayOrder: 20,
    },
    {
        code: '07',
        abreviation: "Centre international d'étude du sport (CIES)",
        displayOrder: 21,
    },
    {
        code: '02',
        abreviation: 'Crèche universitaire',
        displayOrder: 22,
    },
    {
        code: '23',
        abreviation: 'Bibliothèque (FLSH)',
        displayOrder: 23,
    },
    {
        code: '24',
        abreviation: 'Faculté des lettres et sciences humaines (FLSH)',
        displayOrder: 24,
    },
    {
        code: '25',
        abreviation: 'Université de Neuchâtel',
        displayOrder: 25,
    },
    {
        code: '06',
        abreviation: 'L5a',
        displayOrder: 26,
    },
]

export interface IBuilding {
    id: number
    code: string
    fullName: string
    abreviation: string
    designation: string
    adresseLigne1: string
    npa: string
    localite: string
    etageIdParDefaut: number
    routing: boolean
}

export interface IBuildingImage {
    id: number
    imgDataUrl: any
}

let buildingImages: IBuildingImage[]

export { buildingsOrder, buildingImages }
