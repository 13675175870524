import axios, { AxiosInstance } from 'axios'

export class OccupationApiAxios {
    private static instance: AxiosInstance

    static getInstance(): AxiosInstance {
        if (!OccupationApiAxios.instance) {
            const instance = axios.create({
                baseURL: process.env.REACT_APP_OCCUPATIONSALLE_API,
            })
            OccupationApiAxios.instance = instance
        }
        return OccupationApiAxios.instance
    }
}
