import moment from 'moment'
import { Event } from 'react-big-calendar'
/**
 * Parse occupation service response
 */
const parseEvents = (jour: any): Event[] => {
    const { occupation } = jour
    const events: Event[] = []
    //check if we have more than events in this jour
    if (Array.isArray(occupation)) {
        occupation.forEach((occ: any) => {
            const ev = parseEventDetails(occ)
            events.push(ev)
        })
    } else {
        const myEv = parseEventDetails(occupation)
        events.push(myEv)
    }
    return events
}
/**
 * Parse reservation bloc
 */
const parseEventDetails = (data: any): Event => {
    return {
        title: data.typeoccupation,
        start: moment(data.debutLocal).toDate(),
        end: moment(data.finLocal).toDate(),
        resource: data,
    }
}

export { parseEvents }
