import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            Login: {
                loginText: 'Welcome to',
                applicationName: 'Desk',
                forgetPassword: 'Forget password ?',
                signIn: 'Login',
                resetPassword: 'Reset your password',
                resetBtn: 'Reset',
                password: 'Password',
                username: 'Username',
                email: 'E-mail',
            },
            Header: {
                french: 'je parle français',
                english: 'I speak English',
                deutsch: 'Ich spreche Deutsch',
                logout: 'Logout',
            },
            Footer: {
                operatedBy: 'Operated By',
                serverName: 'Server name',
                connected: 'Connected since',
                version: 'Version',
            },
            Error: {
                sorry: 'Oh, Sorry',
                text: 'Something bad happened during the load of this page',
                redirect: 'You will be redirected to login page',
                seconds: 'Seconds',
            },
            Selection: {
                logout: 'Logout',
                slectionOne: 'Please select your building',
                slectionTow: 'Please select your room',
                creationDate: 'Creation date',
                occupied: 'Occupied by',
                fixedDesks: 'Fixed desks',
                personelDesks: 'Personel desks',
            },
            Main: {
                language: 'Language',
                tickets: 'Tickets',
                qrs: 'Convocations',
                waitingTickets: 'Tickets waiting',
                waitingTicketsQR: 'Convocations waiting',
                processingTickets: 'Tickets in process',
                processingTicket: 'Ticket being processed',
                arrviedClient: 'Client arrived',
                calledClient: 'Client called',
                servingStarted: 'Beginning of treatment',
                servingEnded: 'End of treatment',
                cancelledClient: 'Client canceled',
                servedClient: 'Servi',
                servedClientCallNext: 'Served, Next Client',
                processedTickets: 'Tickets processed',
                connectedDesks: 'Connected Desks',
                servedTickets: 'Tickets already processed',
                abandonedTickets: 'Abandoned tickets',
                location: 'Location',
                nextClient: 'Next client',
                reCallClient: 'Re-call',
                byBassClient: 'Next',
                timeToDesk: 'Time of arrival at desk',
                number: 'Number',
                service: 'Service',
                personalDesk: 'Personal',
                genericDesk: 'Fixed',
                distributor: 'Distributor',
                issueHour: 'Issued at',
                appointementHour: 'Appointment time',
                clientId: 'Client ID',
                clientName: 'Client name',
                advisorEmail: "Advisor's E-mail",
                callClient: 'Call',
                byBassClientBtn: 'Bypass',
                serviceDuratin: 'Service duration',
                fwdClient: 'Forward',
                waitingTimeQr: 'Waiting time',
                validateQr: 'Validate',
                waitingTime: 'Waiting time',
                emptyService: 'No services for the moment',
                fwdTicket: 'Forward the ticket',
                fwdService:
                    'Choose a service to which the ticket will be redirected',
                fwdServiceServed: 'The previous service was performed',
                fwdServiceOnTop: 'Put the ticket at the top of the queue',
                cancelBtn: 'Cancel',
                fwdBtn: 'Forward',
                receivedHour: 'Received time',
                receivedBy: 'Received by',
            },
        },
        fr: {
            Login: {
                loginText: "Bienvenue dans l'application",
                applicationName: 'Guichet',
                forgetPassword: 'Mot de passe oublié ?',
                signIn: "S'identifier",
                resetPassword: 'Rinitialisez votre mot de passe',
                resetBtn: 'Réinitialiser',
                password: 'Mot de passe',
                username: "Nom d'utilisateur",
                email: 'Adresse électronique',
                requestResetPassword: 'Réinitialisation du mot de passe',
                reqBtn: 'Envoyer',
                errorChangePassword:
                    'Désolé, Une erreur est survenue lors du traitement de votre requête',
                successChangePassword:
                    'Votre mot de passe à été changé avec succès',

                errorReqChangePassword:
                    'Désolé, Une erreur est survenue lors du traitement de votre requête',
                successReqChangePassword:
                    'Une demande de réinitialisation de votre mot de passe a été envoyée à votre adresse email que vous nous avez communiqué. ',
            },
            Header: {
                french: 'je parle français',
                english: 'I speak English',
                deutsch: 'Ich spreche Deutsch',
                logout: 'Se déconnecter',
            },
            Footer: {
                operatedBy: 'Opéré par',
                serverName: 'Nom du serveur',
                connected: 'Connecté depuis',
                version: 'Version',
            },
            Error: {
                sorry: 'Oh, Désolé',
                text:
                    'Quelque chose s’est mal passé lors du chargement de cette page.',
                redirect: 'Vous serez redirigé vers la page de connexion',
                seconds: 'Secondes',
            },
            Selection: {
                logout: 'Se déconnecter',
                slectionOne: 'Veuillez sélectionner votre bâtiment',
                slectionTow: 'Veuillez sélectionner votre salle',
                creationDate: 'Date de Création',
                occupied: 'Occupé par',
                fixedDesks: 'Guichets fixes',
                personelDesks: 'Guichets personnels',
            },
            Main: {
                language: 'Langue',
                tickets: 'Tickets',
                qrs: 'Convocations',
                waitingTickets: 'Tickets en attente',
                waitingTicketsQR: 'Convocations en attente',
                processingTickets: 'Tickets en cours de traitement',
                processingTicket: 'Ticket en cours de traitement',
                arrviedClient: 'Client arrivé',
                calledClient: 'Client appelé',
                servingStarted: 'Début du traitement',
                servingEnded: 'Fin du traitement',
                cancelledClient: 'Client annulé',
                servedClient: 'Servi',
                servedClientCallNext: 'Servi, Client Suivant',
                processedTickets: 'Tickets traités',
                connectedDesks: 'Guichets connectés',
                servedTickets: 'Tickets déjà traités',
                abandonedTickets: 'Tickets abandonnés',
                location: 'Emplacement',
                nextClient: 'Client suivant',
                reCallClient: 'Rappeler',
                byBassClient: 'Suivant',
                timeToDesk: 'Durée d’arrivée au guichet',
                number: 'Numéro',
                service: 'Prestation',
                personalDesk: 'Personnel',
                genericDesk: 'Fixe',
                distributor: 'Distributeur',
                issueHour: "Heure d'émission",
                appointementHour: 'Heure du rendez-vous',
                clientId: 'ID client',
                clientName: 'Nom du client',
                advisorEmail: 'E-mail du conseiller',
                callClient: 'Appeler',
                byBassClientBtn: 'Passer',
                serviceDuratin: 'Durée du service',
                fwdClient: 'Faire suivre',
                waitingTimeQr: "Délai d'attente",
                validateQr: 'Valider',
                waitingTime: "Temps d'attente",
                emptyService: 'Pas de services pour le moment',
                fwdTicket: 'Faire suivre le ticket',
                fwdService:
                    'Choisissez un service vers lequel le ticket sera redirigé',
                fwdServiceServed: 'La prestation précédente a été effectuée',
                fwdServiceOnTop: 'Placer le ticket en tête de file',
                cancelBtn: 'Annuler',
                fwdBtn: 'Rediriger',
                receivedHour: 'Heure de réception',
                receivedBy: 'Reçu par',
            },
            Validations: {
                tooShort: 'Trop court!',
                tooLong: 'Trop long!',
                required: 'Requis',
                invalidEmail: 'Email invalide',
                passwordLength:
                    'La longueur du mot de passe doit être de 8 caractères ou plus!',
                passwordMatch: 'Les mots de passe doivent correspondre',
            },
        },
        de: {
            Login: {
                loginText: 'Willkommen bei',
                applicationName: 'Desk-Anwendung',
                forgetPassword: 'Passwort vergessen?',
                signIn: 'Verbinden',
                resetPassword: 'Setzen Sie Ihr Passwort zurück',
                resetBtn: 'Rücksetzen',
                password: 'Passwort',
                username: 'Benutzername',
                email: 'E-Mail-Adresse',
            },
            Header: {
                french: 'je parle français',
                english: 'I speak English',
                deutsch: 'Ich spreche Deutsch',
                logout: 'Abmelden',
            },
            Footer: {
                operatedBy: 'Betrieben von',
                serverName: 'Servername',
                connected: 'Verbunden seit',
                version: 'Version',
            },
            Error: {
                sorry: 'Oh, entschuldigung',
                text: 'Beim Laden dieser Seite ist ein Fehler aufgetreten.',
                redirect: 'Sie werden zur Anmeldeseite weitergeleitet',
                seconds: 'Sekunden',
            },
            Selection: {
                logout: 'Abmelden',
                slectionOne: 'Bitte wählen Sie Ihre Site aus',
                slectionTow: 'Bitte wählen Sie Ihren Händler',
                creationDate: 'Erstellungsdatum',
                occupied: 'Beschäftigt durch',
                fixedDesks: 'Feste Zähler',
                personelDesks: 'Persönliche Zähler',
            },
            Main: {
                language: 'Sprache',
                tickets: 'Tickets',
                qrs: 'Einberufungen',
                waitingTickets: 'Tickets warten',
                waitingTicketsQR: 'Vorladung ausstehend',
                processingTickets: 'Tickets in Bearbeitung',
                processingTicket: 'Ticket wird bearbeitet',
                arrviedClient: 'Kunde ist angekommen',
                calledClient: 'Kunde hat angerufen',
                servingStarted: 'Behandlungsbeginn',
                servingEnded: 'Behandlungsende',
                cancelledClient: 'Kunde storniert',
                servedClient: 'Serviert',
                servedClientCallNext: 'Serviert, Client Weiter',
                processedTickets: 'Tickets bearbeitet',
                connectedDesks: 'Verbundene Fenster',
                servedTickets: 'Tickets bereits bearbeitet',
                abandonedTickets: 'Verlassene Tickets',
                location: 'Lage',
                nextClient: 'Nächster Kunde',
                reCallClient: 'Merken',
                byBassClient: 'Folgende',
                timeToDesk: 'Ankunftszeit am Schalter',
                number: 'Anzahl',
                service: 'Nutzen',
                personalDesk: 'Personal',
                genericDesk: 'Fest',
                distributor: 'Spender',
                issueHour: 'Zeitpunkt der Ausgabe',
                appointementHour: 'Terminvereinbarung',
                clientId: 'Kundennummer',
                clientName: 'Name des Kunden',
                advisorEmail: 'E-Mail des Beraters',
                callClient: 'Anruf',
                byBassClientBtn: 'Verbringen',
                serviceDuratin: 'Dauer des Dienstes',
                fwdClient: 'Vorwärts',
                waitingTimeQr: 'Wartezeit',
                validateQr: 'Validate',
                waitingTime: 'Wartezeit',
                emptyService: 'Keine Dienste für den Moment',
                fwdTicket: 'Leiten Sie das Ticket weiter',
                fwdService:
                    'Wählen Sie einen Dienst, zu dem das Ticket umgeleitet wird',
                fwdServiceServed: 'Der vorherige Service wurde durchgeführt',
                fwdServiceOnTop:
                    'Stellen Sie das Ticket ganz oben in die Warteschlange',
                cancelBtn: 'sSornieren',
                fwdBtn: 'Forward',
                receivedHour: 'Empfangszeit',
                receivedBy: 'Erhalten von',
            },
        },
        it: {
            Login: {
                loginText:
                    "Benvenuti nell'applicazione Deske, autenticare per iniziare",
                forgetPassword: 'Dimenticare la password ?',
                signIn: 'Accesso',
                resetPassword: 'Reimposta la tua password',
                resetBtn: 'Ripristina',
            },
            Header: {
                french: 'je parle français',
                english: 'I speak English',
                deutsch: 'Ich spreche Deutsch',
            },
            Footer: {
                operatedBy: 'Operato da',
                serverName: 'Nome del server',
                connected: 'Connesso da allora',
                version: 'Versione',
            },
            Error: {
                sorry: 'Oh scusa',
                text:
                    'È successo qualcosa di brutto durante il caricamento di questa pagina',
                redirect: 'Verrai reindirizzato alla pagina di accesso',
                seconds: 'Secondi',
            },
            Selection: {
                logout: 'Logout',
                slectionOne: 'Please select your site',
                slectionTow: 'Please select your Desk',
                creationDate: 'Creation date',
                occupied: 'Occupied by',
            },
            Main: {
                helpMessage: 'Press to get a ticket',
                scan: 'Scan your documents',
                next: 'Next',
                prev: 'Previous',
                sorry: 'Oh, Sorry',
                unavailable1: 'T  his service',
                unavailable2: 'currently is not available',
                reader: 'Reader',
                codeBar: 'Barcode Scanner',
                orQrCode: 'Or QRcode',
                infoServiceMessage: 'Present yourself at desk A',
                printing: 'Printing ...',
                retryConnectionTitle: 'Connection error',
                retryConnectionMsg1:
                    'Something bad happened during the refresh of this page',
                retryConnectionMsg2: 'New attempt is in progress, please wait.',
                qrCodeHelp:
                    'Please place the code that appears on your documents  suitably in front of the barcode reader of the terminal to be able to scan it',
                wrongScanPosition: 'Incorrect Positioning',
                directionScanPostion:
                    ' Please place the code appropriately in front of the bar code reader.',
                scanErrorPersist:
                    'If the problem persists, please use the assistance of the available staff.',
            },
        },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    // ns: ['translations'],
    // defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
    },

    react: {
        wait: true,
    },
})

export default i18n
