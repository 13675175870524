import React, { useEffect, useState } from 'react'
import * as QueryString from 'query-string'
import SelectionMainController from '../Selection/SelectionMainController'
import RoomSelection from '../Selection/RoomSelection/RoomSelection'
import RoomCalendar from '../RoomCalendar/RoomCalendar'

interface IParams {
    buildingId: number | undefined
    salleId: number | undefined
    forecast: number | undefined
}
export const Home = () => {
    const [params, setParams] = useState<IParams>({
        buildingId: undefined,
        salleId: undefined,
        forecast: undefined,
    })
    const queryParams = window.location.search
    useEffect(() => {
        const urlParams = QueryString.parse(queryParams)
        if (urlParams) {
            const buildingId = urlParams.buildingId
                ? ((urlParams.buildingId as unknown) as number)
                : undefined
            const salleId = urlParams.salleId
                ? ((urlParams.salleId as unknown) as number)
                : undefined
            const forecast = urlParams.forecast
                ? ((urlParams.forecast as unknown) as number)
                : undefined
            setParams({
                buildingId,
                salleId,
                forecast,
            })
        }
    }, [queryParams])

    const isHome = (): boolean => {
        return !params.buildingId && !params.salleId ? true : false
    }

    const isRoom = (): boolean => {
        return params.buildingId && !params.salleId ? true : false
    }

    const isCalendar = (): boolean => {
        return params.salleId ? true : false
    }

    return isHome() ? (
        <SelectionMainController />
    ) : isRoom() ? (
        <RoomSelection buildingId={params.buildingId} />
    ) : isCalendar() ? (
        <RoomCalendar salleId={params.salleId} forecast={params.forecast} />
    ) : (
        <div> loading ...</div>
    )
}
