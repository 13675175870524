import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles/styles.scss'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/es-us'

import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import App from './components/App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </I18nextProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
