import axios, { AxiosInstance } from 'axios'

export class BdloApiAxios {
    private static instance: AxiosInstance

    static getInstance(): AxiosInstance {
        if (!BdloApiAxios.instance) {
            axios.defaults.headers.common['x-bilune-api-key'] = 'abcd'
            const instance = axios.create({
                baseURL: process.env.REACT_APP_BDLO_API,
            })

            BdloApiAxios.instance = instance
        }
        return BdloApiAxios.instance
    }
}
