import React, { useState, useEffect } from 'react'
import { BdloApiAxios } from '../../BdloApiAxios'
import BuildingSelection from './BuildingSelection/BuildingSelection'
import { IBuilding, buildingsOrder } from '../cacheManager'
import ScreenLoader from '../ScreenLoader'

const SelectionMainController = (props: any) => {
    const [buildings, setBuildings] = useState<IBuilding[]>()

    useEffect(() => {
        const axiosApiLayer = BdloApiAxios.getInstance()
        let unmounted = false
        const getData = async () => {
            try {
                const res = await axiosApiLayer.get('batiments')
                const data = res.data.map((element: IBuilding) => {
                    const b = buildingsOrder.find(
                        (b) => b.code === element.code
                    )
                    if (b) {
                        return {
                            ...element,
                            // abreviation: b.abreviation,
                        }
                    }
                    return b
                })
                if (!unmounted) {
                    setBuildings(data)
                }
            } catch (e) {}
        }
        getData()
        return () => {
            unmounted = true
        }
    }, [])

    if (buildings && buildings.length > 0) {
        return <BuildingSelection data={buildings} />
    } else {
        return <ScreenLoader />
    }
}

export default SelectionMainController
