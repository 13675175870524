import React from 'react'
import { withRouter } from 'react-router-dom'
import { back } from '../../../images'
import { useTranslation } from 'react-i18next'

const SelectHead = (props: any) => {
    const { t } = useTranslation('Selection')

    return (
        <div className="select-head">
            <div className="left hide">
                <button className="btn btn-back">
                    <img src={back} alt="back" />
                </button>
            </div>

            <div className="center">
                <h1 className="intro-text">{t('slectionOne')}</h1>
            </div>
        </div>
    )
}

export default withRouter(SelectHead)
