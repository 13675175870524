import React from 'react'

const Footer = (props: any) => {
    const { isOccupied } = props
    return (
        <footer
            className="footer"
            style={{
                background: `${isOccupied ? '#FFA500' : '#008000'}`,
            }}
        >
            <div className="center">
                <p>
                    Cette salle est actuellement{' '}
                    {`${isOccupied ? 'occupée' : 'libre'}`}
                </p>
            </div>
        </footer>
    )
}

export default Footer
