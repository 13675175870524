/**
|--------------------------------------------------
| icons 
|
|--------------------------------------------------
*/
import close from '../styles/images/icons/close.svg'
import consulting from '../styles/images/icons/consulting.svg'
import consultingGreen from '../styles/images/icons/consulting-green.svg'
import deutsch from '../styles/images/icons/deutsch.svg'
import disconnection from '../styles/images/icons/disconnection.svg'
import english from '../styles/images/icons/english.svg'
import error from '../styles/images/icons/error.svg'
import french from '../styles/images/icons/france.svg'
import iconBan from '../styles/images/icons/icon-ban.svg'
import iconConsultingBlue from '../styles/images/icons/icon-consulting-blue.svg'
import iconInfo from '../styles/images/icons/icon-info.svg'
import iconScanBlue from '../styles/images/icons/icon-scan-blue.svg'
import information from '../styles/images/icons/information-popUp.svg'
import oops from '../styles/images/icons/oops.svg'
import printing from '../styles/images/icons/printing.svg'
import qrCode from '../styles/images/icons/qr-code.svg'
import qrCodeGray from '../styles/images/icons/qr-code-gray.svg'
import scanDocuments from '../styles/images/icons/scan-documents.svg'
import signIn from '../styles/images/icons/sign-in.svg'
import logout from '../styles/images/icons/logout.svg'
import back from '../styles/images/icons/back.svg'
import lock from '../styles/images/icons/lock.svg'
import userDefault from '../styles/images/icons/default-user.jpg'
import bin from '../styles/images/icons/bin.svg'

import checkMark from '../styles/images/icons/check-mark.svg'
import pen from '../styles/images/icons/pen.svg'

import penWhite from '../styles/images/icons/pen-white.svg'
import switzerland from '../styles/images/icons/switzerland.svg'
import groupEmptyIcon from '../styles/images/icons/group-empty-icon.svg'
import cloudComputing from '../styles/images/icons/cloud-computing.svg'
import flagFrance from '../styles/images/icons/flag-France.svg'
import flagGermany from '../styles/images/icons/flag-germany.svg'
import flagUK from '../styles/images/icons/flag-united-kingdom.svg'

import flagUSA from '../styles/images/icons/flagUSA.svg'

import avatarDefault from '../styles/images/main/avatar-default.svg'

import logoBCN from '../styles/images/main/logo-BCN.svg'

import smapleUser from '../styles/images/main/MauriceKouzi.svg'

import scanDocumentsError from '../styles/images/main/scan-documents-error.svg'

import iconDashboard from '../styles/images/icons/icon-dashboard.svg'
import iconManagement from '../styles/images/icons/icon-management.svg'
import iconConfiguration from '../styles/images/icons/icon-configuration.svg'
import iconMonitoring from '../styles/images/icons/icon-monitoring.svg'
import menu from '../styles/images/icons/menu.svg'
import plus from '../styles/images/icons/plus.svg'
import plusBlue from '../styles/images/icons/plus-blue.svg'
import plusBlue2 from '../styles/images/icons/plus-blue2.svg'
import notification from '../styles/images/icons/notification.svg'
import removeGray from '../styles/images/icons/remove-gray.svg'
import penBlue from '../styles/images/icons/pen-blue.svg'
import penBlue2 from '../styles/images/icons/pen-blue2.svg'
import logoutBlue from '../styles/images/icons/logout-blue.svg'
import binLg from '../styles/images/icons/bin-lg.svg'
import unisexIcon from '../styles/images/icons/unisex-icon.svg'
import unisexAvatar from '../styles/images/icons/unisex-avatar.svg'
import closeWhite from '../styles/images/icons/close-white.svg'
import emplacementsEmptyIcon from '../styles/images/icons/emplacements-empty-icon.svg'
import configurationEmptyIcon from '../styles/images/icons/configuration-empty-icon.svg'
import guichetsEmptyIcon from '../styles/images/icons/guichets-empty-icon.svg'

import sign from '../styles/images/icons/sign.svg'
import serviceForwardEmpty from '../styles/images/icons/service-forward-empty.svg'

import gallery from '../styles/images/icons/gallery.svg'

import frameing from '../styles/images/icons/framing_icon.png'

import sidebarToggleIcon from '../styles/images/icons/sidebar-toggle-icon.svg'

import logoTicketing from '../styles/images/main/logo_TQM.png'
import background01 from '../styles/images/main/background-01.png'
import background02 from '../styles/images/main/background-02.png'
import background03 from '../styles/images/main/background-03.png'

import background04 from '../styles/images/main/background-04.png'
import background05 from '../styles/images/main/background-05.png'
import background06 from '../styles/images/main/background-06.png'
import background07 from '../styles/images/main/background-07.png'
import background08 from '../styles/images/main/background-08.png'
import background09 from '../styles/images/main/background-09.png'
import background10 from '../styles/images/main/background-10.png'
import spacedBackground1 from '../styles/images/main/spaced-background-1.png'

/**
|--------------------------------------------------
| backgrounds
|--------------------------------------------------
*/
import errorPageInnerBg from '../styles/images/main/error-page-inner-bg-1.png'

import ticketWaiting from '../styles/images/icons/ticket-waiting.svg'
import document from '../styles/images/icons/document.svg'
import ticketServis from '../styles/images/icons/ticket-servis.svg'
import ticketAbandoned from '../styles/images/icons/ticket-abandoned.svg'
import userBlueCircle from '../styles/images/icons/user-blue-circle.svg'
import markerSmall from '../styles/images/icons/marker-small.svg'
import counter from '../styles/images/icons/counter.svg'
import iconInfoBlue from '../styles/images/icons/icon-info-blue.svg'

import stopwatch from '../styles/images/icons/stopwatch.svg'
import userChecked from '../styles/images/icons/user-checked.svg'
import userServe from '../styles/images/icons/user-serve.svg'
import forward from '../styles/images/icons/forward.svg'

import logoUniNE from '../styles/images/icons/UNINE_logo.jpg'
import imageNA from '../styles/images/icons/cb-404.png'

export {
    close,
    consulting,
    consultingGreen,
    deutsch,
    disconnection,
    english,
    error,
    french,
    iconBan,
    iconConsultingBlue,
    iconInfo,
    iconScanBlue,
    information,
    oops,
    printing,
    qrCode,
    qrCodeGray,
    scanDocuments,
    errorPageInnerBg,
    scanDocumentsError,
    logoTicketing,
    background01,
    background02,
    background03,
    background04,
    background05,
    background06,
    background07,
    background08,
    background09,
    background10,
    signIn,
    logout,
    back,
    lock,
    spacedBackground1,
    userDefault,
    logoBCN,
    iconDashboard,
    iconManagement,
    iconConfiguration,
    iconMonitoring,
    menu,
    notification,
    plus,
    bin,
    smapleUser,
    checkMark,
    pen,
    penWhite,
    avatarDefault,
    switzerland,
    plusBlue,
    removeGray,
    plusBlue2,
    penBlue,
    logoutBlue,
    binLg,
    penBlue2,
    groupEmptyIcon,
    unisexIcon,
    unisexAvatar,
    configurationEmptyIcon,
    cloudComputing,
    flagFrance,
    flagGermany,
    flagUK,
    closeWhite,
    emplacementsEmptyIcon,
    guichetsEmptyIcon,
    gallery,
    sidebarToggleIcon,
    ticketWaiting,
    document,
    ticketServis,
    ticketAbandoned,
    userBlueCircle,
    markerSmall,
    counter,
    iconInfoBlue,
    stopwatch,
    userChecked,
    userServe,
    forward,
    sign,
    serviceForwardEmpty,
    flagUSA,
    logoUniNE,
    imageNA,
    frameing,
}
